<template lang="html">
  <div class="bind">
    <div class="head">
      <img src="../../assets/image/bindBk.png" alt="">
    </div>
    <div class="desc">
      目前绑定的手机号为
    </div>
    <div class="phone">
      15002364895
    </div>
    <div class="btn">
      <van-button type="primary" to="/account/verification" round>更换手机号</van-button>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'bind',
    data() {
      return {};
    }
  };
</script>

<style lang="scss" scoped>
  .bind {
    .head {
      img {
        width: 285px;
        height: 322px;
        display: block;
        margin: 62px auto 0 auto;
      }
    }
    .desc {
      margin-top: 50px;
      font-size: 28px;
      color: #000000;
      text-align: center;
    }
    .phone {
      font-size: 48px;
      color: #85C226;
      text-align: center;
      margin-top: 20px;
    }
    .btn {
      width: 468px;
      height: 96px;
      margin: 80px auto 0 auto;
    }
  }
</style>
